$sidebar-width: 17.5%;
$sidebar-width-mobile: 100%;
$header-height: 64px;

.authPagesWrapper {
  position: relative;

  .main {
    transition: margin-left 0.75s;
    overflow-y: none;
    background-color: #f4f5f6;
    position: relative !important;

    @media only screen and (max-width: 992px) {
      margin-left: 0;

      &.noSidebar {
        margin-left: 0%;
      }
    }

    .children {
      overflow-y: hidden;
      overflow-x: auto;
      margin: 0;
      // background-color: #f4f5f6; old color
      background-color: #fff;
      position: relative;
      min-height: 100vh;

      .manipulateChildren {
        padding: 30px 20px;
        margin-top: $header-height;
        @media only screen and (max-width: 992px) {
          padding: 5px;
          margin-top: 90px;
        }
      }

      //Toggle Button
      .menuToggleBtn {
        cursor: pointer;
        height: 55px;
        position: fixed;
        padding: 10px;
        box-sizing: border-box;
        z-index: 100;
        top: 70px;
        display: none;

        @media only screen and (max-width: 992px) {
          display: block;
          padding: 5px;
          top: 65px;
        }

        span {
          background: #262a62;
          display: block;
          width: 30px;
          height: 6px;
          border-radius: 5px;
          margin-bottom: 6px;
          -webkit-transition: all linear;
          transition: all linear;

          @media only screen and (max-width: 992px) {
            height: 4px;
            width: 25px;
            margin-bottom: 4px;
          }
        }
        &.open {
          right: 20px;
          transition: right ease-in-out 500ms;
          span {
            &:nth-child(1),
            &:nth-child(3) {
              transform: translate(0px, 13px) rotate(-45deg) scalex(1.3);
              margin: 0;
            }
            &:nth-child(2) {
              height: 0;
              margin: 0;
            }
            &:nth-child(3) {
              transform: translate(0px, 9px) rotate(45deg) scalex(1.3);
            }
          }
        }
      }
    }
  }
  .main.onBoardingNoMenu {
    margin-left: $sidebar-width;
    @media (max-width: 992px) {
      margin-left: 0;
    }
  }
  .sidebar {
    height: 100%;
    padding-top: 0px !important;
    background-color: #fff;
    position: fixed !important;
    z-index: 1042;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    display: block;
    transition: all ease-in-out 500ms;

    @media only screen and (max-width: 992px) {
      min-width: $sidebar-width-mobile;
      max-width: $sidebar-width-mobile;
      padding-top: 4rem !important;

      &.hideSidebar {
        display: none;
      }
    }
  }
}
