.centralizeAccountAndBVNSearchPage {
  width: 50%;
  margin: 0 auto;

  .wide {
    width: 200%;
    margin-left: -50%;

    @media (max-width: 991px) {
      width: 100%;
      margin-left: unset;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}
