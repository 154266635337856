.alignTermsPage {
  width: 70%;
  padding: 10px;
  text-align: justify;

  p,
  li {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.048px;
    color: var(--cardBorderColor);
  }

  li {
    margin-bottom: 10px !important;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}
