.dealSlipStatusContainer {
  .Card {
    height: 70vh;
    padding-top: 5rem;
  }

  .CardContent {
    margin-top: 5rem;

    & > p {
      color: #170d33;
      text-align: center;
      margin-top: 1rem;
    }

    & > .ActionButtons {
      margin: 4rem auto;
      width: 29rem;
      height: 15rem;

      .PreviewButton {
        margin-top: 1rem;
        background-color: #f8f5ff;
        color: #713fff;
      }
    }
  }
}
