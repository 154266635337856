.uploadedDocCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--colorWhite);
  border: 1px solid var(--colorMidGrey);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 1rem 0;

  .left {
    max-width: 80%;
    min-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-right: 1rem;
    }
  }

  .right {
    cursor: pointer;
  }
}
