$header-height: 64px;

.headerSection {
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  height: $header-height;
  position: fixed;
  left: 17.5%;
  // headersection 17.5% is gotten from $sidebar-width: 17.5% in auth component subtract 100% from it  you get 82.5%;
  top: 0;
  min-width: 82.5%;
  max-width: 82.5%;
  padding: 10px 20px;
  z-index: 100;

  @media only screen and (max-width: 992px) {
    font-size: 0.75rem;
    padding: 10px;
    left: 5%;
  }
  // for phones like galaxy fold
  @media only screen and (max-width: 300px) {
    font-size: 0.5rem;
    left: -8%;
  }
  @media only screen and (max-width: 450px) {
    font-size: 0.5rem;
    background-color: #fff;
    left: 0;
    // headersection 17.5% is gotten from $sidebar-width: 17.5% in auth component subtract 100% from it  you get 82.5%;
    min-width: 100%;
    max-width: 100%;
  }
  .actionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #170d33;
    .notificationBox {
      margin: 0 10px;
      position: relative;
      width: 20px;
      height: 21.5px;
      cursor: pointer;

      // @media only screen and (max-width: 500px) {
      //   display: none;
      // }

      img {
        position: relative;
        min-height: 100%;
        max-height: 100%;
        max-width: 100%;
        max-width: 100%;
      }

      .notificationDot {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: red;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        z-index: 1;
      }
    }
    .verticalLine {
      border-left: 1px solid #b2aebb;
      height: 16px;
    }
    .profileNameBox {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid var(--colorMidPurple);
      position: relative;
      margin: 0 10px;

      .profileNameInnerBox {
        border-radius: 50%;
        border: 2px solid var(--colorMidPurple);
        position: absolute;
        top: 0px;
        left: 0px;
        width: 36px;
        height: 36px;

        .profileNameInnerBoxWithContent {
          background-color: var(--colorWhite);
          position: absolute;
          border-radius: 50%;
          top: 0px;
          left: 0px;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--colorDarkPurple);
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }

    // Bell Icon Area
    // .notificationBox {
    //   .dropdownNotificationMenu {
    //     margin-top: 100%;
    //     padding: 24px 16px;

    //     .notificationHeader {
    //       display: flex;
    //       align-items: flex-start;
    //       gap: 10px;
    //       justify-content: space-between;
    //       border-bottom: 1px solid var(--colorMidGrey);
    //       margin-bottom: 16px;
    //     }

    //     .notifyheading {
    //       font-weight: 600;
    //       line-height: 24px;
    //     }
    //     .clear_close {
    //       display: flex;
    //       align-items: center;
    //       gap: 10px;
    //     }
    //     .clear_all {
    //       color: var(--colorBackgroundHeaderBlue);
    //       font-weight: 400;
    //       line-height: 16px;
    //       line-break: normal;
    //     }
    //     .notification {
    //       display: flex;
    //       padding: 16px 8px;
    //     }

    //     .notification:hover,
    //     .notification:active {
    //       background-color: var(--colorLighterGrey);
    //       font-weight: bolder;
    //     }
    //     .notification_overview {
    //       height: 60vh;
    //       overflow-y: scroll;
    //     }

    //     .notification_content {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: flex-start;
    //       justify-content: flex-end;
    //     }

    //     .notification_title {
    //       display: inline-block;
    //       border: none;
    //       margin-bottom: 8px;
    //       font-size: 14px;
    //       font-weight: bold;
    //     }
    //     .notifyAlert {
    //       display: flex;
    //       min-width: 344px;
    //       justify-content: space-between;
    //       align-items: flex-start;
    //       padding-right: 10px;
    //     }
    //     .notification_time {
    //       margin-bottom: 8px;
    //       margin-top: 8px;
    //     }
    //     .seeMore {
    //       border: none;
    //       background: none;
    //       font-size: 14px;
    //       line-height: 16px;
    //       text-align: left;
    //       font-weight: 600;
    //       cursor: pointer;
    //       padding: 0;
    //       color: var(--colorBlue);
    //     }

    //     .notificationContentDot {
    //       border-radius: 50%;
    //       width: 8px;
    //       height: 8px;
    //       background-color: #f08829;
    //       margin-right: 20px;
    //     }
    //   }
    // }
    .notificationBox {
      position: relative;
      .dropdownNotificationMenu {
        position: absolute;
        top: 100%;
        right: -90px;
        margin-top: 100%;
        padding: 24px 16px;
        background: #fff;
        .notificationHeader {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          justify-content: space-between;
          border-bottom: 1px solid var(--colorMidGrey);
          margin-bottom: 16px;
        }

        .notifyheading {
          font-weight: 600;
          line-height: 24px;
          color: #000;
        }
        .clear_close {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .clear_all {
          color: var(--colorBackgroundHeaderBlue);
          font-weight: 400;
          line-height: 16px;
          line-break: normal;
          color: #722bcf;
        }
        .notification {
          display: flex;
          flex-direction: column;
          color: #000;
        }

        .notification_content:hover,
        .notification_content:active {
          background-color: var(--colorLighterGrey);
          font-weight: bolder;
        }
        .notification_overview {
          height: 60vh;
          overflow-y: auto;
        }

        .notification_content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          margin-bottom: 40px;
          padding: 16px 8px;
        }

        .notification_title {
          display: inline-block;
          border: none;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: bold;
        }
        .notifyAlert {
          display: flex;
          min-width: 344px;
          justify-content: space-between;
          align-items: flex-start;
          padding-right: 10px;
        }
        .notification_time {
          margin-bottom: 8px;
          margin-top: 8px;
        }
        .seeMore {
          border: none;
          background: none;
          font-size: 14px;
          line-height: 16px;
          text-align: left;
          font-weight: 600;
          cursor: pointer;
          padding: 0;
          color: var(--colorBlue);
          outline: none;
        }

        .notificationContentDot {
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background-color: #f08829;
          margin-right: 20px;
        }
      }
    }
    // end of bell notification area
    // update on task
    .dropdown {
      .dropdownButtonArea {
        padding: 10px;
      }
      .dropdownMenu {
        text-align: left;
        padding: 12px;
        border: 1px solid var(--colorMidGrey);
        background-color: var(--colorWhite);
        border-radius: 10px;
        color: #24292e;
        min-width: 200px;
        margin-top: 28px;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

        .profileName {
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
        }
        .profileEmail {
          font-weight: 400;
          font-size: 14px;
        }

        .navLink {
          display: block;
          padding: 10px;
          color: var(--colorDarkPurple);
          font-size: 16px;
          letter-spacing: 0.4px;
          cursor: pointer;

          &:hover {
            font-weight: bold;
            background-color: #f2f5f9;
            border-radius: 10px;
          }

          &.logoutLink {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.headerSection.headerSectionScrollNav {
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  @media only screen and (max-width: 992px) {
    // background-color: transparent;
    background-color: white;
  }
  @media only screen and (max-width: 450px) {
    background-color: white;
    left: 0;
    min-width: 100%;
    max-width: 100%;
  }
}
