.contactAlign {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 85%;

  @media (max-width: 991px) {
    width: 80%;
  }
}
.contactFormHeading {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  color: var(--colorDarkerGrayShade);
}
.contactCard {
  margin-top: 2.5rem;
  border: 1px solid var(--colorGrayCard);
  border-radius: 20px;
  background-color: var(--colorGrayCard);
  padding: 1.5rem;
}
.contactCardsub {
  background-color: #fff;
  padding: 1.5rem;
}
.textAreaInfo {
  color: var(--colorLightGrey);
  padding-top: 1.25rem;
  padding-bottom: 2.5rem;
}
