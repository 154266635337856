.LoanOfferContainer {
  .Card {
    height: 70vh;
  }

  .DeclineAction {
    color: #713fff;
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
  }

  .CardContent {
    margin-top: 5rem;

    & > p {
      color: #170d33;
      text-align: center;
      margin-top: 1rem;
    }

    & > .OfferTable {
      margin: 4rem auto;
      background-color: #f8f5ff;
      width: 24rem;
      height: 15rem;
      border-radius: 10px;

      .Header {
        padding-top: 1.5rem;
        padding-left: 1.6rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid #d7dce0;
      }

      .Body {
        padding-top: 1rem;
        padding-left: 1.6rem;
        padding-bottom: 0.75rem;

        .cellHeader {
          color: #81909d;
          font-size: 0.75rem;
        }

        .cellContent {
          color: #0d0f11;
          font-weight: 700;
        }
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .LoanOfferContainer {
    .Card {
      height: 100vh;
    }
  }
}
