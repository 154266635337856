.alignTermsPage {
  // h3 {
  //   color: var(--colorDarkerGrayShade);
  //   font-size: 2.5rem;
  //   font-weight: 700;
  //   line-height: 2rem;
  // }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.termsUseSubContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;

  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.termsUseContentList {
  position: static;
  overflow-y: scroll;
  padding: 1.5rem 1.5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 75vh;
  cursor: pointer;
  ol li {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--AdvanclyDarkPersianIndigo);
    padding-top: 0.75rem;
  }

  ol li a {
    color: var(--AdvanclyDarkPersianIndigo);
    &:hover {
      color: var(--colorMidBlue);
    }
  }
  .active {
    color: var(--colorMidBlue);
  }

  @media (max-width: 991px) {
    margin-top: 3rem;
    height: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

// terms of use Description

.termsOfUseDescription {
  height: 75vh;
  position: static;
  border-radius: 1.25rem;
  padding: 1.5rem;
  border: 1px solid var(--colorGrayCard);
  background-color: var(--colorGrayCard);
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;

  scrollbar-width: none;
  -ms-overflow-style: none;

  dl {
    background-color: #fff;
    padding: 1.5rem;
  }
  dt {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: var(--colorDarkerGrayShade);
  }
  dd {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    color: var(--AdvanclyDarkPersianIndigo);
  }

  @media (max-width: 991px) {
    margin-top: 10rem;
    height: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
