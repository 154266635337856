.UserManagementHeader {
  padding-bottom: 2.5rem;
}

.assignedRole {
  white-space: pre-line;
}

.updateUserConfirmationCancelButton,
.advanclyIconDialog {
  margin-bottom: 1.5rem;
}
.confirmationHeader {
  color: var(--AdvanclyDarkPersianIndigo);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.editRoleMain {
  padding-top: 0.82rem;
  padding-bottom: 1.18rem;
  &:hover {
    background-color: var(--colorSidebarBackground);
  }
}
.editRoleList {
  font-size: 0.875rem;
  color: var(--AdvanclyBodyText);
  font-weight: 400;
}
.buttonEditUserRole {
  padding-top: 3.75rem;
}
