.stepWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  border: 1px solid #f0f0f0;
  margin-bottom: 16px;
  color: var(--cardBorderColor);
  background-color: #fff;
  border-radius: 19px;
  .button {
    padding-left: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .button {
      margin-top: 16px;
    }
  }

  .stepInnerWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-weight: bold;
      margin-bottom: 0.75rem;
      color: var(-- colorBackgroundPurple);
      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .subtitle {
      font-size: 14px;
      line-height: 16px;
      text-align: justify;
    }

    .stepBox {
      border-radius: 50%;
      border: 1px solid var(--colorBackgroundPurple);
      color: var(--colorBackgroundPurple);
      min-width: 32px;
      max-width: 32px;
      min-height: 32px;
      max-height: 32px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }

      &.stepCompleted {
        border: 1px solid #a7dea8;

        background-color: #a7dea8;
        color: #26ad25;
      }
    }
  }
}
