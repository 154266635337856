.DashboardCard_container {
  .DashboardCard_details {
  }

  .value {
    color: var(--advancly-indigo-blue-text);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
  }
  .label {
    color: #8b8699;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: 0.025rem;
  }
}
.DashboardCard_container.active_color {
  background-color: var(--colorBackgroundPurple);
}
.DashboardCard_value.active_color {
  p {
    color: #fff;
  }
}
