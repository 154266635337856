.signUpLayout {
  background-color: var(--white);
  display: grid;
  min-height: 100vh;
  grid-template-columns: 3fr 2fr;

  .flexRadio {
    display: flex;
    gap: 0.5rem;
  }
  .button {
    position: relative;
  }

  .button label,
  .button input {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .button input[type="radio"] {
    opacity: 0.011;

    z-index: 100;
  }
  .button input[type="radio"] + label {
    border: none;
    outline: 0;
    background: #f2f5f9;
    border-radius: 24px;
  }

  .button input[type="radio"]:checked + label {
    display: block;
    border: 1px solid #713fff;
    background: #ffffff;

    border-radius: 24px;
    // padding: 8px, 12px, 8px, 12px;
    color: #170d33;
  }

  .button label {
    cursor: pointer;
    z-index: 90;
    line-height: 1.8em;
  }
  .iconEmail {
    position: relative;
  }
  .iconstick {
    color: #4ecc4e;
    position: absolute;
    top: 43%;
    right: 7%;
    z-index: 10;
    padding-right: 0.5rem;
  }

  .productCheckboxes {
    display: flex;
    align-items: center;
  }

  .productCheckboxes label {
    cursor: pointer;
    display: flex;
    font-size: smaller;
  }

  .productCheckboxes input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  .productCheckboxes label::before {
    content: "";
    width: 1.1em;
    height: 1.1em;
    margin-right: 0.5em;
    border: 0.05em solid #a5dba5;
    border-radius: 100%;
    padding: 12px;
  }

  .productCheckboxes label:hover::before,
  .productCheckboxes input[type="checkbox"]:hover + label::before {
    background-color: #a2cca2;
    // #a5dba5
  }

  .productCheckboxes input[type="checkbox"]:focus + label::before {
    box-shadow: 0 0 50px black;
  }

  .productCheckboxes input[type="checkbox"]:disabled + label,
  .productCheckboxes input[type="checkbox"]:disabled {
    color: #90a0c2;
    cursor: default;
    border: 1px solid #90a0c2;
  }

  .productCheckboxes input[type="checkbox"]:checked + label::before {
    content: "\002714";
    background-color: #a5dba5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #25ad25;
  }

  .productCheckboxes input[type="checkbox"]:disabled + label::before {
    background-color: #ccc;
    border-color: #999;
  }

  .embedProduct {
    @media (max-width: 349px) {
      font-size: 0.7rem;
    }
  }
  .sparkProduct {
    @media (max-width: 695px) {
      margin-top: 1rem;
    }
    @media (max-width: 349px) {
      font-size: 0.7rem;
    }
  }
  .middleline {
    height: 111px;
    border: 1px solid #f0f0f0;
    @media (max-width: 710px) {
      display: none;
    }
  }
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    padding-left: 4.38rem;
    flex-wrap: nowrap;
    background-color: transparent;

    @media (max-width: 780px) {
      flex-wrap: wrap;
    }
    .sparkLogoLink {
      width: 60%;
      padding-top: 48px;
    }
    .onboardingBackButton {
      width: 60%;
    }
    form {
      width: 60%;
      padding-top: 10px;

      @media (max-width: 768px) {
        width: 60%;
      }
    }
    .mainOtpDiv {
      margin-left: 2px;
      :first-child {
        margin-left: 0px !important;
      }
      :focus {
        color: var(--colorBackgroundBlue) !important;
      }
      .OtpStyling {
        border: 1px solid #bac2c9;
        border-radius: 10px;
        margin-left: 24px;
        outline: none;

        &:hover {
          outline: none;
        }
        &:focus {
          color: var(--colorBackgroundBlue) !important;
          outline: 1px solid #713fff;
          border: 1px solid #713fff;
        }
      }
      @media (max-width: 500px) {
        .mainOtpDiv {
          font-size: small;
        }
        .OtpStyling {
          font-size: small;
          margin-left: 10px !important;
        }
      }
      @media (max-width: 360px) {
        .mainOtpDiv {
          font-size: 4px;
        }
        .OtpStyling {
          font-size: small;
          margin-left: 3px !important;
          text-align: left;
        }
      }
    }
  }
  .right {
    background-color: var(--colorSidebarBackground);

    @media (max-width: 992px) {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5.75rem;
      padding-bottom: 5.69rem;

      .illustrator {
        max-width: 100%;
      }
      .imgHeading {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--colorBackgroundPurple);
      }

      .imgParagraph {
        text-align: center;
        color: var(--colorLightGrey);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }
      .highlightContainer {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding-top: 3.75rem;
        div:nth-child(1) {
          background-color: var(--colorBackgroundPurple);
        }
        div {
          width: 7.5rem;
          height: 0.25rem;
          border-radius: 1.25rem;
          background-color: var(--AdvanclyColorGrey);
        }
      }
    }
  }
}

// row pb-4 pt-4 mr-3

// col ml-4 pt-2 pb-2 pl-2 pr-2 text-center
