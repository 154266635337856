.toolTipContainer {
  position: absolute;
  // right: 1rem;
  width: 100%;
  max-width: 25.375rem;
  width: 25.375rem;
  z-index: 3000;
  padding-top: 3.19rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: white;
  border-radius: 0.625rem;
  box-shadow: 0px 24px 54px 0px rgba(40, 41, 61, 0.2);

  .toolTipHeading {
    h5 {
      color: var(--colorMidPurple);
      font-size: 1rem;
      white-space: nowrap;
      font-weight: 500;
      line-height: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.81rem;
      padding-right: 15.1rem;
    }
  }
  hr {
    color: var(--AdvanclyDarkPersianIndigo);
  }
  .toolTipText {
    p {
      text-align: center;
      color: var(--AdvanclyDarkPersianIndigo);
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
