.getStartedCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--colorWhite);
  text-align: center;
  color: var(--cardBorderColor);
  font-size: 14px;
  padding: 36px 16px 28px 16px;
  border: 1px solid var(--colorMidGrey);
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);

  svg {
    margin-bottom: 1rem;
  }

  .title {
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 1rem;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}
