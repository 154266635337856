.mainAlert {
  display: flex;
  position: fixed;
  align-items: center;
  margin: 0 auto;
  top: 1.5%;
  left: 20.5%;
  width: 70%;
  z-index: 992;
  font-weight: bold;
  animation-name: slideDown;
  animation-duration: 4s;
  @keyframes slideDown {
    0%,
    100% {
      -webkit-transform: translateY(-50px);
    }
    10%,
    90% {
      -webkit-transform: translateY(0px);
    }
  }

  .alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    margin: 0;
    background-color: var(--colorRed);
    color: var(--colorWhite);
    position: relative;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    font-size: 14px;
    word-break: break-all;

    .close {
      position: absolute;
      right: 20px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--colorWhite);
      color: var(--colorRed);
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    &.success {
      background-color: #eaf9ea;
      color: #25ad25;

      .close {
        color: var(--colorLightGreen);
      }
    }

    &.info {
      background-color: var(--colorLightBlue);
      color: var(--colorWhite);

      .close {
        color: var(--colorLightBlue);
      }
    }

    .message {
      font-weight: bold;
      width: 80%;
      max-width: 80%;
    }
  }
}
