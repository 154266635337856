.backButton {
  color: var(--colorMidPurple);
  cursor: pointer;
  // height: clamp(20px, 2.5vw, 24px);
  // width: clamp(20px, 2.5vw, 24px);
  line-height: 28px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem; /* 100% */
  letter-spacing: -0.00313rem;
  padding-right: 0.5rem;
}
