.access_denied_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 700;
    color: #3a434b;
    padding-top: 3rem;
    font-size: 1.5rem;
  }
  p {
    color: #3a434b;
    font-size: 0.875rem;
  }
}
