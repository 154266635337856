.alignBusinessInfo {
  display: grid;
  grid-template-columns: 5fr 4fr 4fr 4fr;
  grid-gap: 20px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .alignNameSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    .nameSection {
      .name {
        font-weight: bold;
        font-family: var(--fontBold);
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;

        @media (max-width: 768px) {
          margin-top: 10px;
        }
      }
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .profilePhoto {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .profileImage {
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      background-color: var(--colorLighterGrey);
      cursor: pointer;
      position: relative;
    }
    svg.profileImage {
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      background-color: var(--colorLighterGrey);
      cursor: pointer;
      padding: 20px;
      fill: var(--colorMidBlue);
    }
    .editButton {
      position: absolute;
      top: 2.9rem;
      left: 4.6rem;
      cursor: pointer;
    }

    .usernameHeading {
      color: var(--colorDarkPurple);
      font-size: 1.25rem;
      font-weight: 700;
    }
    .usernameHandle {
      color: var(--colorLightGrey);
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .ProfileCardMiniTitle {
    font-size: 1.25rem;
    color: var(--AdvanclyDarkPersianIndigo);
    font-weight: 700;
  }
}
