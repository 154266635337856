.resetPasswordLayout {
  background-color: var(--white);
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
  .right {
    background-color: transparent;

    @media (max-width: 992px) {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      a {
        margin-left: 10%;
      }

      .illustrator {
        align-self: center;
        max-width: 100%;
        height: 90vh;
      }
    }
  }
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .sparkLogoLink {
      width: 60%;
      padding-top: 48px;
    }
    form {
      width: 60%;
      padding-top: 70px;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
}
