.getStartedOuterLayout {
  display: flex;
  max-width: 70%;
  min-width: 70%;
  margin: 2.5rem 15%;

  .sparkLogoLink {
    width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    margin: 1.5rem 0%;
    flex-direction: column;
  }
}

.getStartedOuterLayout.extraOuterLayout {
  display: flex;
  max-width: 70%;
  min-width: 70%;
  margin: 2.5rem 15%;
}
.newGetStartedInnerComponent {
  max-width: 60%;
  min-width: 60%;
  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.InnerComponentOfferLetter {
  max-width: 100%;
  min-width: 100%;
  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.newGetStartedInnerBusinessComponent {
  max-width: 75%;
  min-width: 75%;

  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.getStartedInnerLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  padding-left: 3.5rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    padding-left: 0;
  }
  h3 {
    color: var(--colorBackgroundPurple);
  }
  p {
    color: var(--colorDarkPurple);
  }
  &.tiny {
    max-width: 90%;
    min-width: 90%;
    margin: 0 5%;
  }

  .step {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #677684;
  }

  .getStartedCardsLayout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .viewText {
    color: #170d33;

    font-size: 14px;
    font-weight: 400;
  }
  .viewText:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .dateText {
    color: #81909d;
    font-size: 12px;
    font-weight: 400;
  }
}
.requestOnboarding {
  display: flex;
  justify-content: center;
}

.finalPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  max-width: 40%;
  min-width: 40%;
  margin: 0 30%;

  @media (max-width: 768px) {
    max-width: 60%;
    min-width: 60%;
    margin: 0 20%;
  }

  .thumb {
    background-color: var(--colorWhite);
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    color: #677684;
    text-align: center;
    margin: 1rem 0;
  }
}
.titleForUpload {
  color: #170d33;
  // font-size: 0.875rem;
  font-size: 1.3rem;
  // font-weight: 900;
  font-weight: 900;

  .smallSpanTitleRequired {
    color: red;
    font-size: 0.8rem;
    font-weight: 900;
  }
  .smallSpanTitleOptional {
    color: var(--colorMidPurple);
    font-size: 0.8rem;
    font-weight: 900;
  }
}

.inputFileBorder {
  border: 1px solid #d1cfd6 !important;
  border-radius: 10px;
}
.getStartedOnboardBusiness {
  grid-template-columns: repeat(1, 1fr) !important;
}
.offerLetterCards {
  max-width: 90%;
  min-width: 90%;
}

.questionCheckbox input[type="checkbox"]:checked {
  color: "#25AD25";
}

//GetStartedUploadDocuments border line
.hrLine {
  display: block;
  border: 1px solid #d1cfd6;
  width: 100%;
  margin: 30px 0;
}

.cancelboarding {
  border-radius: 10px;
}
.upload_hint_alert {
  margin-left: 12rem;
  @media (max-width: 750px) {
    margin-left: 0.2rem;
  }
}
.upload_loader {
  margin-left: 8rem;
}
