.downloadButton {
  color: var(--colorMidBlue);
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.retryText {
  color: var(--colorMidBlue);
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.photoUrl {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
    border: 50%;
  }
}

.uploadInstructions {
  padding: 0px;
  margin-left: 30px;
}
.multipleUploadCard {
  padding: 4rem;
  border: 1px solid #d7dce0;
  border-radius: 4px;
}
.multipleUploadCardText {
  font-size: 2rem;
}
.multipleUploadSubCard {
  width: 100%;
  padding-bottom: 20px;
  margin: auto;
  background-color: #f8f5ff;
  border: 1px solid #d7dce0;
  border-radius: 10px;

  .uploadBoxIconBorder {
    img {
      margin-bottom: 20px;
      height: 50px;
      width: auto;
    }

    .actionText {
      color: #8b8699;
    }

    padding: 80px;
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.reviewButtonUpload {
  // display: flex;
  // justify-content: center;
  // margin-left: 18rem;
  // width: 50%;
}
