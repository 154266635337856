.backButton {
  margin: 0;
  padding: 0;
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-height: 28px;
    max-height: 28px;
    max-width: 28px;
    min-width: 28px;
  }
}
