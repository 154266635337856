.uploadContainer {
  width: 70rem;
  height: 44rem;
  margin: 1.5rem auto;
  border-radius: 5px;

  .welcomeText {
    color: #170d33;
    text-align: center;
    margin-top: 1rem;
  }

  .title {
    text-align: center;
    margin-top: 4rem;
    font-size: 1.5rem;
  }

  .uploadCard,
  .divider,
  .uploadedDoc {
    width: 29rem;
  }

  .uploadCard {
    height: 9rem;
    border: 1px solid #d7dce0;
    margin: 1.5rem auto;
    background-color: #f8f5ff;
    border-radius: 4px;

    .header {
      text-align: center;
      font-weight: bolder;
      color: #170d33;
      margin-bottom: 0.5rem;

      & + p {
        color: #8b8699;
        text-align: center;

        .browseAction {
          color: var(--colorBackgroundBlue);
          cursor: pointer;
        }
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .divider {
    border: 1px solid #d7dce0;
  }

  .uploadedDoc {
    margin: 0 auto;

    & > .uploadedDocHeader {
      display: flex;
      justify-content: space-between;

      & > h6 {
        color: #170d33;
        margin-bottom: 1rem;
      }

      & > .DownloadDealSlip {
        font-size: 0.8rem;
        padding-left: 1.5rem;
        background-image: url("../../svg/icons/folder_2.svg");
        background-repeat: no-repeat;
      }
    }

    & > .uploadedItem {
      position: relative;

      p {
        font-size: 0.8rem;
        padding-left: 1.5rem;
        background-image: url("../../svg/icons/folder_2.svg");
        background-repeat: no-repeat;
        word-wrap: break-word;
      }

      .deleteIcon {
        margin-top: -0.4rem;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .sendDocument {
    margin: 6rem auto;
    display: block;
    width: 29rem;
  }

  .previewDocument {
    margin: 2rem auto;
    display: block;
    background-color: #ffffff;
    color: #713fff;
    border: 1px solid #713fff;
    width: 100%;

    span {
      margin-left: 0.5rem;
    }

    &:hover {
      color: #713fff;
    }
  }
}

@media only screen and (max-width: 1163px) {
  .uploadContainer {
    width: 100%;
    height: 100%;
    padding: 0 1rem;

    .previewDocument {
      width: 50%;
    }

    .sendDocument {
      margin: 2rem auto;
    }
  }
}
