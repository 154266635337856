.scoreCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid var(--AdvanclyColorGrey);
  padding-left: 2.41rem;
  .subScoreContainer {
    padding-left: 0.5rem;
    .title {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      letter-spacing: 0.025rem;
      color: var(--colorLightGrey);
      white-space: nowrap;
    }

    .value {
      color: var(--AdvanclyDarkPersianIndigo);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
      padding-top: 0.5rem;
    }
  }
}
