$sidebar-horizontal-padding: 30px;

@mixin hoverState {
  // background-color: #e8effd;
  color: var(--colorMidBlue);

  svg {
    fill: var(--colorMidBlue);
  }
}
@mixin selectedActiveState {
  color: var(--colorMidPurple);
  border-left: 4px solid var(--colorMidPurple);
  background: linear-gradient(
    91deg,
    rgba(86, 46, 237, 0.7) -46.39%,
    rgba(255, 255, 255, 0) 35.86%
  );
  .svgIcon {
    fill: var(--colorMidPurple) !important;
  }
}

.selectProductLogo > a {
  display: block;
  list-style: none;
  padding-left: 10px;
  min-width: 100%;
  max-width: 100%;
}
.selectProductLogo a img {
  width: 50%;
}
.listUnstyled.components {
  display: block;
  list-style: none;
  padding-left: 0;
  min-width: 100%;
  max-width: 100%;
  // margin-top: 90px;
  // background-color: var(--colorLightPurple);

  .sidebarLine {
    height: 1px;
    position: relative;
    background-color: var(--colorMidGrey);
    margin: 8px $sidebar-horizontal-padding;

    &.fullWidth {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .mainNavLinkParent {
    width: 250px;
    font-size: 16px;
    color: var(--colorLightGrey);
    text-align: left;
    padding: 12px $sidebar-horizontal-padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .InvestorsOverViewSpanText {
      margin-left: 0.75rem;
    }

    &.selected {
      @include selectedActiveState();
    }

    svg {
      fill: inherit;
      margin: 0;
    }

    &:hover {
      @include selectedActiveState();
    }
    .svgArrowBox {
      margin-left: 1rem;
    }
  }
  .navLink {
    font-size: 16px;
    color: var(--colorLightGrey);
    text-align: left;
    padding: 12px $sidebar-horizontal-padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.selected {
      @include selectedActiveState();
    }

    svg {
      fill: inherit;
      margin: 0;
    }

    &:hover {
      @include selectedActiveState();
    }

    span {
      margin-left: 12px;
    }

    a:hover {
      text-decoration: none;
    }
  }
}
.navLinkNested {
  font-size: 14px;
  color: var(--colorLightGrey);
  text-align: left;
  padding: 10px 30px 10px 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &.selected {
    @include selectedActiveState();
  }

  svg {
    fill: inherit;
    margin: 0;
  }

  &:hover {
    @include selectedActiveState();
  }

  span {
    margin-left: 12px;
  }

  a:hover {
    text-decoration: none;
  }
}
//  Product dropdown menu starts here
.dropdown_menu {
  position: absolute;
  top: 60px;
  left: 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  width: 200px;
}

// .dropdown_menu::before {
//   content: "";
//   position: absolute;
//   top: -5px;
//   left: 20px;
//   height: 20px;
//   width: 20px;
//   background: #fff;
//   transform: rotate(45deg);
// }

.dropdown_menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 500ms ease;
}
.dropdown_menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 500ms ease;
}

//  product dropdown items starts here
.dropdownItem_ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
  margin-top: 0.4rem;
}
.dropdownItem_container {
  .dropdownItem {
    display: flex;
    list-style: none;
    padding: 8px 16px;
    margin-top: 0.4rem;
    &:hover {
      background-color: #f2f5f9;
      border-radius: 10px;
    }
  }
  .dropdownItem.active_product {
    background-color: #f2f5f9;
    border-radius: 10px;
  }

  span {
    display: block;
    font-size: 0.625rem;
    color: #170d33;
  }
  img {
    width: 50%;
  }
  .dropdownItem_product_active {
    opacity: 1;
    width: 1.2rem;
    height: 1rem;
    background-color: #27ae60;
    border: 1px solid #27ae60;
    border-radius: 100%;
    align-self: center;
  }
  .dropdownItem_product_Inactive {
    opacity: 0;
  }
  .advestRedirectIcon {
    align-self: center;
  }
}
.addProduct {
  display: flex;
  align-items: center;
  padding: 1.62rem 2rem 1.62rem 1rem;
  background-color: #fff;
  margin-left: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #f2f5f9;
    border-radius: 10px;
  }

  span {
    align-self: center;
    padding-left: 0.68rem;
  }
}
// for Bootstrap modal
.sidebarModalDialog {
  margin: auto;
}

.sidebarModalContent {
  margin-top: 1rem !important;
  border-radius: 20px;
  border: 1px solid #fff;
  outline: none;
  margin-left: 10rem;
  overflow-y: auto !important;
}
.modalHeader {
  h4 {
    color: var(--colorBackgroundBlue);
    font-weight: 700;
  }
}

.modalMainBody {
  .product_card_main {
    padding: 1.84rem 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 60px 1px #0000001a;
    border-radius: 10px;
    border: 1px solid #fff;
    margin-top: 1rem;
    cursor: pointer;
    overflow-y: auto !important;
  }
  .product_card_header {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
  }

  //CUSTOM CHECKBOX STYLING
  /* The container checkboxes */
  .containerCheckboxes {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .containerCheckboxes input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    // background-color: #a4dba5;
    border: 1px solid #90a0c2;
  }

  /* On mouse-over, add a dark green background color */
  .containerCheckboxes:hover input ~ .checkmark {
    background-color: #d0ded0;
  }

  /* When the checkbox is checked, add a light green background */
  .containerCheckboxes input:checked ~ .checkmark {
    background-color: #a4dba5;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .containerCheckboxes input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .containerCheckboxes .checkmark:after {
    left: 0.4rem;
    top: 0.19rem;

    width: 5px;
    height: 10px;
    border: solid #25ad25;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
