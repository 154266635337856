.loginLayout {
  background-color: var(--white);
  display: grid;
  min-height: 100vh;
  grid-template-columns: 3fr 2fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    padding-left: 4.38rem;
    // align-items: center;
    flex-direction: column;

    .sparkLogoLink {
      margin-top: 48px;
      width: 60%;
    }

    form {
      width: 60%;
      padding-top: 10px;
      .forgot-password {
        margin-left: 10%;
      }

      @media (max-width: 768px) {
        width: 60%;
      }
    }
  }
  .right {
    background-color: var(--colorSidebarBackground);

    @media (max-width: 992px) {
      display: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5.75rem;
      padding-bottom: 5.69rem;

      .illustrator {
        max-width: 100%;
      }
      .imgHeading {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--colorBackgroundPurple);
      }

      .imgParagraph {
        text-align: center;
        color: var(--colorLightGrey);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }
      .highlightContainer {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding-top: 3.75rem;
        div:nth-child(1) {
          background-color: var(--colorBackgroundPurple);
        }
        div {
          width: 7.5rem;
          height: 0.25rem;
          border-radius: 1.25rem;
          background-color: var(--AdvanclyColorGrey);
        }
      }
    }
  }
}
