.widgetWrapper {
  padding: clamp(5px, 2vw, 20px);
  flex: 1 1 auto;
  min-height: 1px;
}

.modalContent {
  width: 150%;
  transform: translateX(-17.5%);

  @media (max-width: 991px) {
    width: 90%;
    transform: translateX(7.5%);
  }
}
