.alignBusinessInfo {
  // display: grid;
  // grid-template-columns: 1fr 2fr;
  // grid-gap: 20px;
  // margin-top: 30px;

  // @media (max-width: 991px) {
  //   grid-template-columns: 1fr;
  // }

  .profilePhoto {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .profileImage {
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      background-color: var(--colorLighterGrey);
      cursor: pointer;
      position: relative;
    }
    svg.profileImage {
      height: 5rem;
      width: 5rem;
      border-radius: 5rem;
      background-color: var(--colorLighterGrey);
      cursor: pointer;
      padding: 20px;
      fill: var(--colorMidBlue);
    }
    .editButton {
      position: absolute;
      top: 2.9rem;
      left: 4.6rem;
      cursor: pointer;
    }
  }
  .usernameHeading {
    color: var(--colorDarkPurple);
    font-size: 1.25rem;
    font-weight: 700;
  }
  .usernameHandle {
    color: var(--colorLightGrey);
    font-size: 0.875rem;
    font-weight: 400;
  }
}
