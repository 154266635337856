.rootMainRequest {
  padding-top: 5rem;
}
.centralizeLoanRequestPages {
  width: 76%;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.loanRequestCard {
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2.06rem;
  padding-right: 2rem;

  background-color: #ffffff;
  margin-top: 2rem;
  border-radius: 20px;
  .loanRequestCardSub {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 400px) {
    .loanRequestCardSub {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .loanrequestButton a button {
    background-color: #f5f6fa;
    border-color: #f5f6fa;
    color: var(--colorMidBlue);
    outline: none;
    border: none;

    &:hover {
      border: 1px solid var(--colorMidBlue);
      font-weight: bold;
    }
  }
}
.searchInput {
  border-radius: "10px" !important;
  outline: none;
}
.borrowerSearchResult {
  margin-top: 0.3rem;
  padding-top: 0.3rem;
  padding: 1rem;
  // height: 12.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 60px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0 0 30px 30px;
}

.borrowerSearchResultResponse {
  text-align: left;
  padding-left: 0.7rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8rem;
}
.borrowerSearchResultResponse:hover {
  background-color: var(--colorHoverState);
}
.disableCursor {
  cursor: none;
  pointer-events: none;
}
.activeCursor {
  cursor: pointer;
}
