.modalCard {
  border: 1px solid #d7dce0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  .radioButton {
    margin-right: 12px;

    &::before {
      content: " ";
      display: inline-block;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 2px solid #3a434b;
    }
  }

  &:hover,
  &.active {
    background-color: #e8effd;
    border-color: #2c6eec;

    h6 {
      color: var(--colorBackgroundBlue);
    }

    .radioButton {
      &::before {
        border: 2px solid var(--colorBackgroundBlue);
      }
    }
  }
}
