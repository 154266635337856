.mainAllInvestments {
  width: 65%;

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.investmentOverviewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox and some other browsers */
  scrollbar-color: transparent transparent; /* For Firefox */
  cursor: pointer;
  /* For WebKit-based browsers (like Chrome and Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar-track {
    background-color: transparent; /* or any color you prefer */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* or any color you prefer */
  }

  /* For Internet Explorer and Edge */
  -ms-overflow-style: none;

  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
}

.investmentOverViewSubContainer {
  display: flex;
  justify-content: center;

  .overviewTextContainer {
    padding-left: 0.5rem;
    .investmentOverviewHeading {
      color: var(--colorLightGrey);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.5rem; /* 171.429% */
      letter-spacing: 0.025rem;
      white-space: nowrap;
    }
    .investmentOverviewParagraph {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
    }
  }
}
.investmentOverViewSubContainer:not(:first-child) {
  padding-left: 2.06rem;

  border-left: 1px solid var(--AdvanclyColorGrey);

  @media only screen and (max-width: 992px) {
    border-left: none;
    padding-left: 0;
  }
}
