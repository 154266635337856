.customInputStyles {
  background-color: #fff;

  &[readonly] {
    color: var(--colorLightGrey);
    background-color: var(--colorGrayCard);
  }
  &:placeholder-shown {
    color: var(--colorLightGrey);
    background-color: var(--colorGrayCard);
  }
  &[disabled] {
    color: var(--colorLightGrey);
    background-color: var(--colorGrayCard);
  }
}
