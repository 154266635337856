//Delete modal

.delete_container {
  border-radius: 20px;
  margin-top: 4rem;
  .delete_paragraph {
    text-align: center;
  }
  .deleteIcon {
    padding-top: 3rem;
  }
  .delete_heading {
    padding-top: 2.146rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

//Editing hooks
.success_paragraph {
  text-align: center;
}
.successIcon {
  padding-top: 3rem;
}
.success_heading {
  padding-top: 2.146rem;
  font-size: 1.5rem;
  font-weight: 700;
}
